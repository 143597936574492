import React from 'react';
import { useAtom } from 'jotai';
import { useQuery } from '@tanstack/react-query';
import { usePathname } from 'next/navigation';
import useNavigator from '@hooks/useAppRouter';
import {
  BottomNavButton,
  BottomNavWrap,
} from '@components/layout/styles/bottomNav.style';
import Home from '@icons/bottom-nav/home.svg';
import Ranking from '@icons/bottom-nav/ranking.svg';
import Profile from '@icons/bottom-nav/profile.svg';
import { userTooltipAtom } from '@src/stores/user';
import userApi from '@src/apis/usersApi';
import PinkTooltip from '@components/common/PinkTooltip';
import useUserInfoStore from '@hooks/useUserInfoStore';

const BottomNav: React.FC = () => {
  const router = useNavigator();
  const pathName = usePathname();
  const [userInfo] = useUserInfoStore();

  const [{ newFinalWinCount, newPickCount }, setTooltip] =
    useAtom(userTooltipAtom);

  useQuery(
    ['tooltip-count'],
    async () => {
      const { data } = await userApi.getMyWinCount();

      const resObj = {
        newFinalWinCount:
          data.totalFinalWinCount -
          data.prevTotalFinalWinCount +
          newFinalWinCount,
        newPickCount:
          data.totalPickCount - data.prevTotalPickCount + newPickCount,
      };

      setTooltip(resObj);

      return {
        isTooltip: resObj.newFinalWinCount > 0 || resObj.newPickCount > 0,
      };
    },
    { keepPreviousData: true, enabled: !!userInfo?.userId },
  );

  const isTooltip = newFinalWinCount > 0 || newPickCount > 0;

  const isPathActive = (path: string) => {
    if (path === '/') {
      return pathName === '/';
    }
    return pathName.startsWith(path);
  };

  const handleClickProfile = async () => {
    if (userInfo) {
      await router.push(`/profile/me`);
    } else {
      await router.replace('/login');
    }
  };

  return (
    <BottomNavWrap id="bottom-nav">
      <BottomNavButton
        onClick={() => router.push('/')}
        active={isPathActive('/')}
      >
        <Home />
        <span>홈</span>
      </BottomNavButton>
      <BottomNavButton
        onClick={() => router.push('/ranking')}
        active={isPathActive('/ranking')}
      >
        <Ranking />
        <span>실시간랭킹</span>
      </BottomNavButton>
      <BottomNavButton
        onClick={handleClickProfile}
        active={isPathActive('/profile')}
      >
        {isTooltip && (
          <PinkTooltip top="-42px">
            {!!newPickCount && (
              <PinkTooltip.Item type="Pick" count={newPickCount} />
            )}
            {!!newFinalWinCount && (
              <PinkTooltip.Item type="FinalWin" count={newFinalWinCount} />
            )}
          </PinkTooltip>
        )}
        <Profile />
        <span>MY랭킹</span>
      </BottomNavButton>
    </BottomNavWrap>
  );
};

export default BottomNav;
