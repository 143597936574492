import styled from 'styled-components';
import React from 'react';
import { usePathname } from 'next/navigation';
import { useSetAtom } from 'jotai';
import Home from '@icons/mobile/home.svg';
import FourCut from '@icons/mobile/4cut.svg';
import Ranking from '@icons/mobile/ranking.svg';
import Profile from '@icons/mobile/profile.svg';
import ActiveHome from '@icons/mobile/active-home.svg';
import ActiveFourCut from '@icons/mobile/active-4cut.svg';
import ActiveRanking from '@icons/mobile/active-ranking.svg';
import ActiveProfile from '@icons/mobile/active-profile.svg';
import QR from '@icons/mobile/QR.svg';
import useAppRouter from '@hooks/useAppRouter';
import { qrAtom } from '@src/stores/qr';

const BottomWrap = styled.div`
  ${({ theme }) => theme.layout.width};
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid #eaecee; // booth/gray200
  height: ${({ theme }) => theme.mobileBottomNavHeight};
  z-index: ${({ theme }) => theme.zIndex.bottomNav};
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const BottomNavButton = styled.button<{ active: boolean }>`
  flex: 1 1 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  color: ${({ theme, active }) =>
    active ? theme.colors.gray100 : theme.colors.gray70};

  span {
    ${({ theme }) => theme.font.body5};
    margin-top: 6px;
    font-size: 11px;
  }
`;

const QRBottomNavButton = styled(BottomNavButton)`
  position: relative;
  top: -12px;
`;

const QRButtonWrap = styled.div`
  width: 50px;
  height: 50px;
  padding: 13px;
  background-color: black;
  border-radius: 50%;
`;

const MobileBottomNav = (): JSX.Element => {
  const router = useAppRouter();
  const pathName = usePathname();
  const setIsModal = useSetAtom(qrAtom);

  const isPathActive = (path: string) => {
    if (path === '/') {
      return pathName === '/';
    }
    return pathName.startsWith(path);
  };

  return (
    <BottomWrap id="mobile-bottom-nav">
      <BottomNavButton
        onClick={() => router.push('/home')}
        active={isPathActive('/home')}
      >
        {isPathActive('/home') ? <ActiveHome /> : <Home />}
        <span>홈</span>
      </BottomNavButton>
      <BottomNavButton
        onClick={() => router.push('/')}
        active={isPathActive('/')}
      >
        {isPathActive('/') ? <ActiveFourCut /> : <FourCut />}
        <span>네컷랭킹</span>
      </BottomNavButton>
      <QRBottomNavButton
        onClick={() => setIsModal(true)}
        active={isPathActive('/')}
      >
        <QRButtonWrap>
          <QR />
        </QRButtonWrap>
        <span>적립</span>
      </QRBottomNavButton>
      <BottomNavButton
        onClick={() => router.push('/ranking')}
        active={isPathActive('/ranking')}
      >
        {isPathActive('/ranking') ? <ActiveRanking /> : <Ranking />}
        <span>실시간랭킹</span>
      </BottomNavButton>
      <BottomNavButton
        onClick={() => router.push('/profile/me')}
        active={isPathActive('/profile')}
      >
        {isPathActive('/profile') ? <ActiveProfile /> : <Profile />}
        <span>MY 랭킹</span>
      </BottomNavButton>
    </BottomWrap>
  );
};

export default MobileBottomNav;
